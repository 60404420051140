import React, { useState } from 'react';
import { graphql } from 'gatsby';

//@ts-ignore
import * as styles from './image-with-statement-block.module.css';
import { RichTextEl } from '../Global/RichText';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import ArrowLgButton from '../Global/ArrowLgButton';
//@ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {
    KontentStringValue,
    CTAConfiguration,
    GeneralImages,
    BrandColor,
    KontentRichText,
    Statement,
    Statements,
} from '../../../types/KontentProps';
import {
    getColor,
    getMediaData,
    getMultiChoice,
    getStatement,
} from '../Global/DataUtils';
import PlainTextElement from '../Global/Headlines';

interface Props extends CTAConfiguration, GeneralImages {
    component_anchor_name?: KontentStringValue;
    content_panel__content_panel_color?: BrandColor;
    content_panel__content_panel_font_color?: BrandColor;
    headline?: KontentStringValue;
    short_rich_description?: KontentRichText;
    statements: Statements;
}

const ImageWithStatementBlock: React.FC<Props> = ({
    headline,
    general_large___small_image_selection__large,
    general_large___small_image_selection__small,
    content_panel__content_panel_color,
    content_panel__content_panel_font_color,
    cta_configuration__cta_label,
    cta_configuration__cta_window_action,
    short_rich_description,
    cta_configuration__cta_location,
    cta_configuration__cta_location_anchor_point,
    statements,
    component_anchor_name,
    ...props
}) => {
    const largeImage = getMediaData(
        general_large___small_image_selection__large
    );

    const smallImage = getMediaData(
        general_large___small_image_selection__small
    );
    const anchorTarget =
        getMultiChoice(cta_configuration__cta_window_action) === 'new_window'
            ? '_new'
            : '_self';
    const contentPanelFontColor = getColor(
        content_panel__content_panel_font_color
    ) as any;
    const contentPanelColor = getColor(
        content_panel__content_panel_color
    ) as any;
    const strokeColor = contentPanelFontColor || '#fff';
    const authorStyles = {
        color: contentPanelFontColor,
        backgroundColor: contentPanelColor,
    };

    const [currentStatement, setCurrentStatement] = useState(0);
    const numStatements = statements?.value?.length;
    const breakpoints = useBreakpoint();

    const handleClickNext = () => {
        setCurrentStatement(currentStatement + 1);
    };

    const handleClickPrev = () => {
        if (currentStatement === 0) {
            setCurrentStatement(numStatements - 1);
        } else {
            setCurrentStatement(currentStatement - 1);
        }
    };

    const renderCta = () => {
        if (!cta_configuration__cta_label) {
            return null;
        }

        return (
            <div className={styles.ctaContainer}>
                {cta_configuration__cta_location && (
                    <CtaLink
                        href={BuildLinkHref(
                            cta_configuration__cta_location,
                            cta_configuration__cta_location_anchor_point
                        )}
                        target={anchorTarget}
                        color={contentPanelFontColor}
                    >
                        {cta_configuration__cta_label?.value}
                    </CtaLink>
                )}
            </div>
        );
    };

    const renderStatement = (statement: Statement, idx: number) => {
        if (breakpoints.l || idx === currentStatement % numStatements) {
            const statementData = getStatement(statement);
            return (
                <div key={idx} className={styles.statement}>
                    <div className={styles.iconWrapper}>
                        {!breakpoints.l && (
                            <ArrowLgButton
                                direction="left"
                                strokeColor={strokeColor}
                                handleClick={handleClickPrev}
                                className={styles.prev}
                            />
                        )}
                        <img
                            src={statementData.icon?.url}
                            alt={statementData.icon?.description}
                            className={styles.icon}
                        />
                        {!breakpoints.l && (
                            <ArrowLgButton
                                direction="right"
                                strokeColor={strokeColor}
                                handleClick={handleClickNext}
                                className={styles.next}
                            />
                        )}
                    </div>
                    {statementData.displayTitle && (
                        <h3>{statementData.displayTitle.value}</h3>
                    )}
                    {statementData?.richDescription?.value && (
                        <div className={styles.statementDescription}>
                            <RichTextEl
                                richText={statementData.richDescription}
                            />
                        </div>
                    )}
                </div>
            );
        }
    };

    return (
        <section className={styles.wrapper} id={component_anchor_name?.value}>
            {largeImage && (
                <picture className={styles.picture}>
                    {smallImage && (
                        <source
                            media="(max-width:992px)"
                            srcSet={smallImage.url}
                        />
                    )}
                    <img src={largeImage.url} alt={largeImage.description} />
                </picture>
            )}
            <div className={styles.contentContainer} style={authorStyles}>
                <div className={styles.innerContent}>
                    {headline?.value && (
                        <h2>
                            <PlainTextElement text={headline} />
                        </h2>
                    )}

                    {short_rich_description?.value && (
                        <div>
                            <RichTextEl richText={short_rich_description} />
                        </div>
                    )}
                    {breakpoints.l && renderCta()}
                </div>

                {statements?.value?.map((statement, idx) =>
                    renderStatement(statement, idx)
                )}

                {!breakpoints.l && renderCta()}
            </div>
        </section>
    );
};

export default ImageWithStatementBlock;

export const fragmentImageWithStatementBlock = graphql`
    fragment ImageWithStatementBlock on kontent_item_component___image_with_statement_block {
        id
        elements {
            component_anchor_name {
                value
            }
            content_panel__content_panel_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            content_panel__content_panel_font_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        id
                        elements {
                            color_hex_value {
                                value
                            }
                        }
                    }
                }
            }
            cta_configuration__cta_label {
                value
            }
            cta_configuration__cta_location {
                ...kontentCtaLocation
            }
            cta_configuration__cta_location_anchor_point {
                value
            }
            cta_configuration__cta_window_action {
                value {
                    codename
                }
            }
            general_large___small_image_selection__large {
                value {
                    ... on kontent_item_media___image {
                        elements {
                            file {
                                value {
                                    url
                                }
                            }
                        }
                    }
                }
            }
            general_large___small_image_selection__small {
                value {
                    ... on kontent_item_media___image {
                        elements {
                            file {
                                value {
                                    description
                                    url
                                }
                            }
                        }
                    }
                }
            }
            headline {
                value
            }
            short_rich_description {
                value
                links {
                    url_slug
                    type
                }
                images {
                    url
                    description
                }
                name
            }
            statements {
                value {
                    ... on kontent_item_content_item___statement_with_icon {
                        id
                        elements {
                            rich_description {
                                value
                                links {
                                    url_slug
                                    type
                                }
                                images {
                                    url
                                    description
                                }
                                name
                            }
                            display_title {
                                value
                            }
                            icon {
                                value {
                                    ... on kontent_item_media___icon {
                                        id
                                        elements {
                                            icon_svg_file {
                                                value {
                                                    url
                                                    description
                                                }
                                            }
                                            wellness_type {
                                                value {
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
