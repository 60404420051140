// extracted by mini-css-extract-plugin
export var contentContainer = "image-with-statement-block-module--contentContainer--a577a";
export var ctaContainer = "image-with-statement-block-module--ctaContainer--da35d";
export var icon = "image-with-statement-block-module--icon--68915";
export var iconWrapper = "image-with-statement-block-module--iconWrapper--cc60b";
export var innerContent = "image-with-statement-block-module--innerContent--37b78";
export var next = "image-with-statement-block-module--next--c4c5a";
export var picture = "image-with-statement-block-module--picture--a104b";
export var prev = "image-with-statement-block-module--prev--04b01";
export var statement = "image-with-statement-block-module--statement--8a1a5";
export var statementDescription = "image-with-statement-block-module--statementDescription--7ee86";
export var wrapper = "image-with-statement-block-module--wrapper--79d65";